.card-title {
    color: black;
    text-align: left;
}

.card {
    overflow-x: auto;
}

.subMenuLink .arrowIconSubmenu::before,
.subMenuLink.collapsed .arrowIconSubmenu::before,
.subMenuLink .arrowIconSubmenu::after {
    content: '';
    position: absolute;
    background: 0 0;
    background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
    border-radius: 2px;
    width: 6px;
    height: 1.5px;
    top: 4px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: rotate(-135deg) translateX(2px);
    -webkit-transform: rotate(-135deg) translateX(2px);
    right: -19px;
}

.subMenuLink .arrowIconSubmenu::after,
.subMenuLink.collapsed .arrowIconSubmenu::after {
    transform: rotate(-45deg) translateX(2px);
    -webkit-transform: rotate(-45deg) translateX(2px);
}


.subMenuLink:not(.collapsed) .arrowIconSubmenu::before {
    transform: rotate(-225deg) translateX(2px);
    -webkit-transform: rotate(-225deg) translateX(2px);
}

.subMenuLink:not(.collapsed) .arrowIconSubmenu::after {
    transform: rotate(45deg) translateX(2px);
    -webkit-transform: rotate(45deg) translateX(2px);
}

.subMenuLink:not(.collapsed) {
    color: #fff;
}

.subMenuLink:not(.collapsed) svg path {
    fill: #fff;
}

#style-1GDWy {
    width: 50% !important;
    margin-left: 25%;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.card-body {
    padding: 0;
}